import React from 'react';
import { ReactComponent as LogoWhite } from 'assets/svgs/logo-white-horizontal.svg';


const Home = () => {
  return (
    <>
    <div className='w-100 h-screen flex flex-col items-center justify-center' style={{background: '#364054'}}>
      <div className='pb-10'>
        <LogoWhite className='' style={{width: '20rem'}}/>
      </div>
    </div>
    </>
  );
}

export default Home;
