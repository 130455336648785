import React from 'react';
import Home from 'marketing/home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { ApolloProvider } from "@apollo/client"
// import client from 'config/apollo'

// <ApolloProvider client={client}>
const App = () => {
  return (
       <Router>
           <Switch>
             <Route exact path="/">
               <Home />
             </Route>
           </Switch>
       </Router>
  );
}

export default App;
